import React from "react";
import './about.css';

export function About(){
    return(
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>About Us</h2>
                    <p>The FMCG group is a group of companies that manage the supply and distribution of low-cost Fast-Moving Consumer Goods (FMCG) to the public and private sector. They also acquire strategically placed Franchises.</p>
                </div>

                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            Mission and Vision
                        </p>
                        <ul>
                            <li><i className="ri-checkbox-circle-fill"/> To supply government with low cost, quality FMCG products</li>
                            <li><i className="ri-checkbox-circle-fill"/> To supply smaller shops with affordable, quality FMCG products</li>
                            <li><i className="ri-checkbox-circle-fill"/> To acquire Franchises and make them profitable</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <p>
                            Companies under FMCG's helm are:
                        </p>
                        <ul>
                            <li><i className="ri-checkbox-circle-fill"/> Linuflex – Supplies FMCG to the Public Sector.</li>
                            <li><i className="ri-checkbox-circle-fill"/> Vasitwize – Supplies FMCG to the Private Sector, mainly shop owners and small businesses.</li>
                            <li><i className="ri-checkbox-circle-fill"/> Explicom – Acquires and operates small enterprises and franchises.</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    );
}
