import React from "react";
import './footer.css';

export function Footer() {
    return (
        <footer id="footer">

            <div className="footer-newsletter">
                <div className="container">
                    <div className="row">
                        {/*<div className="col-lg-6">*/}
                        {/*    <h4>Our Newsletter</h4>*/}
                        {/*    <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>*/}
                        {/*</div>*/}
                        {/*<div className="col-lg-6">*/}
                        {/*    <form action="" method="post">*/}
                        {/*        <input type="email" name="email"/><input type="submit" value="Subscribe"/>*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"/> <a href="#">Home</a></li>
                                <li><i className="bx bx-chevron-right"/> <a href="#">About us</a></li>
                                <li><i className="bx bx-chevron-right"/> <a href="#">Services</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"/> <a href="#">Public Sector</a></li>
                                <li><i className="bx bx-chevron-right"/> <a href="#">Private Sector</a></li>
                                <li><i className="bx bx-chevron-right"/> <a href="#">Franchises</a></li>
                                <li><i className="bx bx-chevron-right"/> <a href="#">Small Enterprises</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h4>Contact Us</h4>
                            <p>
                                A108 Adam Street <br/>
                                New York, NY 535022<br/>
                                United States <br/><br/>
                                <strong>Phone:</strong> +1 5589 55488 55<br/>
                                <strong>Email:</strong> info@fmcg.co.za<br/>
                            </p>

                        </div>

                        <div className="col-lg-3 col-md-6 footer-info">
                            <h3>About FMCG Group</h3>
                            <p>The FMCG group is a group of companies that
                                manage the supply and distribution of low-cost Fast-Moving Consumer Goods (FMCG) to the
                                public and private sector. They also acquire strategically placed Franchises.</p>
                            <div className="social-links mt-3">
                                <a href="#" className="twitter"><i className="bx bxl-twitter"/></a>
                                <a href="#" className="facebook"><i className="bx bxl-facebook"/></a>
                                <a href="#" className="instagram"><i className="bx bxl-instagram"/></a>
                                <a href="#" className="google-plus"><i className="bx bxl-skype"/></a>
                                <a href="#" className="linkedin"><i className="bx bxl-linkedin"/></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>FMCG Group</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Developed by <a href="https://www.yelah.co.za/">Yelah</a>
                </div>
            </div>
        </footer>
);
}
