import React from "react";
import './topBar.css';

export function TopBar() {
    return (
        <div id="topbar" className="d-none d-lg-flex align-items-center fixed-top ">
            <div className="container d-flex align-items-center">
                <div className="contact-info mr-auto">
                    <ul>
                        <li>
                            <i className="icofont-envelope"/>
                            <a href="mailto:info@fmcg.co.za">info@fmcg.co.za</a>
                        </li>
                        <li>
                            <i className="icofont-phone"/> +1 5589 55488 55
                        </li>
                        <li>
                            <i className="icofont-clock-time icofont-flip-horizontal"/> Mon-Fri 9am - 5pm
                        </li>
                    </ul>
                </div>
                <div className="cta">
                    <a href="#about" className="scrollto">Get Started</a>
                </div>
            </div>
        </div>
    );
}
