import React from "react";
import './hero.css';

export function Hero() {
    return (
        <section id="hero" className="d-flex justify-cntent-center align-items-center">
            <div id="heroCarousel" className="container carousel carousel-fade" data-ride="carousel">

                <div className="carousel-item active">
                    <div className="carousel-container">
                        <h2 className="animate__animated animate__fadeInDown">Welcome to <span>FMCG Group</span></h2>
                        <p className="animate__animated animate__fadeInUp">The FMCG group is a group of companies that
                            manage the supply and distribution of low-cost Fast-Moving Consumer Goods (FMCG) to the
                            public and private sector. They also acquire strategically placed Franchises.</p>
                        <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read
                            More</a>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="carousel-container">
                        <h2 className="animate__animated animate__fadeInDown">Public Sector</h2>
                        <p className="animate__animated animate__fadeInUp">The FMCG group is a group of companies that manage the supply and distribution of low-cost Fast-Moving Consumer Goods (FMCG) to the public and private sector. They also acquire strategically placed Franchises..</p>
                        <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read
                            More</a>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="carousel-container">
                        <h2 className="animate__animated animate__fadeInDown">Private Sector</h2>
                        <p className="animate__animated animate__fadeInUp">The FMCG group is a group of companies that manage the supply and distribution of low-cost Fast-Moving Consumer Goods (FMCG) to the public and private sector. They also acquire strategically placed Franchises.</p>
                        <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read
                            More</a>
                    </div>
                </div>

                <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"/>
                    <span className="sr-only">Previous</span>
                </a>

                <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"/>
                    <span className="sr-only">Next</span>
                </a>

            </div>
        </section>
    );
}
