import React from "react";
import './header.css';

export function Header() {
    return (
        <div id="header" className="fixed-top ">
            <div className="container d-flex align-items-center">

                <h1 className="logo mr-auto"><a href="#header" className="scrollto">FMCG Group</a></h1>
                {/*Uncomment below if you prefer to use an image logo */}
                {/*<a href="#header" className="logo mr-auto scrollto"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>*/}
                <nav className="nav-menu d-none d-lg-block">
                    <ul>
                        <li className="active"><a href="#header">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#services">Services</a></li>
                        <li className="drop-down"><a href="">Subsidiaries</a>
                            <ul>
                                <li><a href="#">Linuflex</a></li>
                                <li><a href="#">Vasitwize</a></li>
                                <li><a href="#">Explicom</a></li>
                            </ul>
                        </li>
                        <li><a href="#footer">Contact</a></li>

                    </ul>
                </nav>

            </div>
        </div>
    );
}
