import './App.css';
import {TopBar} from "./TopBar/TopBar";
import {Header} from "./Header/Header";
import {Hero} from "./Hero/Hero";
import {Footer} from "./Footer/Footer";
import {IconBoxes} from "./IconBoxes/IconBoxes";
import {About} from "./About/About";
import {Services} from "./Services/Services";

import jQuery from 'jquery';
import 'jquery.easing';

function App() {

    (function ($) {

        const initializeNavMenu = () => {
            // Smooth scroll for the navigation menu and links with .scrollto classes
            var scrolltoOffset = $('#header').outerHeight() - 2;
            $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
                // if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                const target = $(this.hash);
                if (target.length) {
                    e.preventDefault();

                    let scrollTo = target.offset().top - scrolltoOffset;

                    if ($(this).attr("href") === '#header' || $(this).attr("href") === 'index.html#header') {
                        scrollTo = 0;
                    }

                    $('html, body').animate({
                        scrollTop: scrollTo
                    }, 1500, 'easeInOutExpo');

                    if ($(this).parents('.nav-menu, .mobile-nav').length) {
                        $('.nav-menu .active, .mobile-nav .active').removeClass('active');
                        $(this).closest('li').addClass('active');
                    }

                    if ($('body').hasClass('mobile-nav-active')) {
                        $('body').removeClass('mobile-nav-active');
                        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                        $('.mobile-nav-overly').fadeOut();
                    }
                    return false;
                }
                // }
            });

            // Activate smooth scroll on page load with hash links in the url
            $(document).ready(function () {
                if (window.location.hash) {
                    var initial_nav = window.location.hash;
                    if ($(initial_nav).length) {
                        var scrollto = $(initial_nav).offset().top - scrolltoOffset;
                        $('html, body').animate({
                            scrollTop: scrollto
                        }, 1500, 'easeInOutExpo');
                    }
                }
            });

            // Mobile Navigation
            if ($('.nav-menu').length) {
                const $mobile_nav = $('.nav-menu').clone().prop({
                    class: 'mobile-nav d-lg-none'
                });
                $('body').append($mobile_nav);
                $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
                $('body').append('<div class="mobile-nav-overly"></div>');

                $(document).on('click', '.mobile-nav-toggle', function (e) {
                    $('body').toggleClass('mobile-nav-active');
                    $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                    $('.mobile-nav-overly').toggle();
                });

                $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
                    e.preventDefault();
                    $(this).next().slideToggle(300);
                    $(this).parent().toggleClass('active');
                });

                $(document).click(function (e) {
                    const container = $(".mobile-nav, .mobile-nav-toggle");
                    if (!container.is(e.target) && container.has(e.target).length === 0) {
                        if ($('body').hasClass('mobile-nav-active')) {
                            $('body').removeClass('mobile-nav-active');
                            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                            $('.mobile-nav-overly').fadeOut();
                        }
                    }
                });
            } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
                $(".mobile-nav, .mobile-nav-toggle").hide();
            }
        }
        if ($(window).scrollTop() > 100) {
            $('#header').addClass('header-scrolled');
            $('#topbar').addClass('topbar-scrolled');
        }

        // Navigation active state on scroll
        const nav_sections = $('section');
        const main_nav = $('.nav-menu, #mobile-nav');

        // Back to top button
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('#header').addClass('header-scrolled');
                $('#topbar').addClass('topbar-scrolled');
                $('.back-to-top').fadeIn('slow');
            } else {
                $('#header').removeClass('header-scrolled');
                $('#topbar').removeClass('topbar-scrolled');
                $('.back-to-top').fadeOut('slow');
            }

            const cur_pos = $(this).scrollTop() + 200;

            nav_sections.each(function () {
                const top = $(this).offset().top,
                    bottom = top + $(this).outerHeight();

                if (cur_pos >= top && cur_pos <= bottom) {
                    if (cur_pos <= bottom) {
                        main_nav.find('li').removeClass('active');
                    }
                    main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
                }
                if (cur_pos < 300) {
                    $(".nav-menu ul:first li:first").addClass('active');
                }
            });
        });

        $('.back-to-top').click(function () {
            $('html, body').animate({
                scrollTop: 0
            }, 1500, 'easeInOutExpo');
            return false;
        });

        // Scroll to a section with hash in url
        $(window).on('load', function () {

            if ($('#preloader').length) {
                $('#preloader').delay(100).fadeOut('slow', function () {
                    $(this).remove();
                });
            }

            if (window.location.hash) {
                const initial_nav = window.location.hash;
                if ($(initial_nav).length) {
                    const target_hash = $(initial_nav);
                    const scrollto_hash = target_hash.offset().top - $('#header').outerHeight();
                    $('html, body').animate({
                        scrollTop: scrollto_hash
                    }, 1500, 'easeInOutExpo');
                    $('.nav-menu .active, .mobile-nav .active').removeClass('active');
                    $('.nav-menu, .mobile-nav').find('a[href="' + initial_nav + '"]').parent('li').addClass('active');
                }
            }

            initializeNavMenu();
        });
    })(jQuery);


    return (
        <>
            <TopBar/>
            <Header/>
            <Hero/>
            <main id="main">
                <IconBoxes/>
                <About/>
                <Services/>
            </main>
            <Footer/>
            <a href="#" className="back-to-top"><i className="ri-arrow-up-line"/></a>
            <div id="preloader"/>
        </>
    );
}

export default App;
